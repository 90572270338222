import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';


const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('reduxState');
        if (serializedState === null) {
            return undefined; // Let the reducers initialize the state
        }
        return JSON.parse(serializedState);
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
};

const saveStateToLocalStorage = (state:any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reduxState', serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
};

const persistedState = loadStateFromLocalStorage();

const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
    console.log('Dispatching action:', action);
    console.log('Previous state:', store.getState());
    const result = next(action);
    console.log('Next state:', store.getState());
    return result;
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: persistedState,
    devTools: process.env.NODE_ENV !== 'production',
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware().concat(loggerMiddleware),
});

store.subscribe(() => {
    console.log("updated")
    saveStateToLocalStorage(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;