import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment-timezone';
import { updateAccessData } from '../../util/util';
import apiClient from "../../util/apiClient";

// Async thunk for login
export const login = createAsyncThunk(
    'user/login',
    async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
        try {
            // Make API call to login the user
            const response = await apiClient('auth', 'POST', { email, password });
            console.log(response)
            // Return the user data from the API response
            if (!response.data) {
                throw new Error('No data in response');
            }

            // Return the user data from the API response
            return response.data;
        } catch (error: any) {
            // Reject with an error message
            return rejectWithValue(error.response?.data?.message || 'Login failed');
        }
    }
);

// Define user state
interface UserInfo {
    userInfo: any;
    adminUser: boolean;
    companyInfo: any;
    departmentInfo: any;
    access: string[];
    language: string;
    translations: { [key: string]: string };
    masterOptions: any[];
    projects: any[];
}

const initialState: UserInfo = {
    userInfo: {},
    adminUser: false,
    companyInfo: {},
    departmentInfo: {},
    access: [],
    language: 'en',
    translations: {},
    masterOptions: [],
    projects: [],
};

// Timer setup
let timer: NodeJS.Timeout | null = null;

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUser: (state, action: PayloadAction<any>) => {
            const access = action.payload.roleData?.access || [];

            if (action.payload.company && action.payload.userType === 'Admin') {
                access.push('adminAccess');
            } else if (action.payload.userType === 'Admin') {
                access.push('add-company');
            }

            updateAccessData(access);

            state.userInfo = action.payload;
            state.companyInfo = action.payload.companyData;
            state.departmentInfo = action.payload.departmentData?.id ? action.payload.departmentData : false;
            console.log("u1")
        },

        setUserData: (state, action: PayloadAction<any>) => {
            Object.assign(state.userInfo, action.payload);
            console.log("u2")
        },
        setProjectsData:(state, action: PayloadAction<any>) => {
            Object.assign(state.projects, action.payload);
            console.log("u3")
        },
        setCompanyData: (state, action: PayloadAction<any>) => {
            Object.assign(state.companyInfo, action.payload);
            console.log("u4")
        },
        setDepartmentData:(state,action:PayloadAction<any>)=>{
            let dataToAssign = action.payload.departmentData?.id ? action.payload.departmentData : false;
            Object.assign(state.departmentInfo, dataToAssign);
            console.log("u5")
        },
        setAccessData:(state,action:PayloadAction<any>)=>{
            const access = action.payload.roleData?.access || [];
            if (action.payload.company && action.payload.userType === 'Admin') {
                access.push('adminAccess');
            } else if (action.payload.userType === 'Admin') {
                access.push('add-company');
            }

            state.access = access;
            updateAccessData(access);
            // Optionally save token or user information in local storage
            localStorage.setItem('ACCOUNTING_USER', JSON.stringify(action.payload));
            console.log("u6")
        },

        setTimerCount: (state, action: PayloadAction<any>) => {
            // Timer logic here...
        },

        setUserStateToDefault:(state,action:PayloadAction<any>)=>{
            Object.assign(state,{userInfo: {},
                adminUser: false,
                companyInfo: {},
                departmentInfo: {},
                access: [],
                language: state.language,
                translations: {},
                masterOptions: [],
                projects: []})
            console.log("u7")
        },

        setTranslation: (state, action: PayloadAction<{ translations: any; language: string }>) => {
            state.translations = action.payload.translations;
            state.language = action.payload.language;
            console.log("u8")
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                // Handle loading state
                state.userInfo = null;
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
                // Handle successful login
                console.log(action)
                state.userInfo = action.payload;
                state.companyInfo = action.payload.companyData;
                state.departmentInfo = action.payload.departmentData?.id ? action.payload.departmentData : false;

                // Update access data based on user role
                const access = action.payload.roleData?.access || [];
                if (action.payload.company && action.payload.userType === 'Admin') {
                    access.push('adminAccess');
                } else if (action.payload.userType === 'Admin') {
                    access.push('add-company');
                }

                state.access = access;
                updateAccessData(access);

                // Optionally save token or user information in local storage
                localStorage.setItem('ACCOUNTING_USER', JSON.stringify(action.payload));
            })
            .addCase(login.rejected, (state, action: PayloadAction<any>) => {
                // Handle login error
                state.userInfo = null;
                console.error('Login failed:', action.payload);
            });
    }
});

export const { getUser, setUserData,setProjectsData, setTimerCount, setTranslation ,setCompanyData,setDepartmentData,setAccessData,setUserStateToDefault} = userSlice.actions;

export default userSlice.reducer;
