import { Button as AntdButton, ButtonProps } from "antd";
import clsx from "clsx";
import Translate from "./Translate/Translate";
import { ButtonHTMLType, ButtonType } from "antd/lib/button";

export type TButton = {
  type?: ButtonHTMLType;
  appearance?: ButtonType | "secondary";
  success?: boolean;
  color?: string;
  label?: string;
} & Omit<ButtonProps, "type" | "htmlType">;

export default function Button({
  type = "button",
  appearance = "default",
  className,
  success,
  color,
  label,
  ...props
}: TButton) {
  return (
    <AntdButton
      className={clsx(className, success && "ant-btn-success")}
      htmlType={type}
      color={color ?? "primary"}
      type={appearance as ButtonType}
      {...props}
    >
      <Translate>
        {label}
        {props.children}
      </Translate>
    </AntdButton>
  );
}
