import React from 'react';
import invoice from '../../assets/images/loader.svg';

// Define types for the props
interface LoaderBoxProps {
  loader?: string | boolean;
  noData?: string | boolean;
  style?: React.CSSProperties;
}

const LoaderBox: React.FC<LoaderBoxProps> = ({ loader, noData, style }) => (
    <>
      {loader && (
          <div className="loading-content" style={style}>
            <img src={invoice} alt="Loading" />
            <h1>{typeof loader === 'boolean' ? 'Loading..' : loader}</h1>
          </div>
      )}
      {!loader && noData && (
          <div className="loading-content" style={style}>
            <img src={invoice} alt="No Data found" />
            <h1>{typeof noData === 'boolean' ? 'No Data found..' : noData}</h1>
          </div>
      )}
    </>
);

export default LoaderBox;
