import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
    language: string ;
    translations: { [key: string]: string };
}

const initialState: LanguageState = {
    language: 'en',
    translations: {}
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setTranslations: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.translations = action.payload;
        }
    }
});

export const { setLanguage, setTranslations } = languageSlice.actions;
export default languageSlice.reducer;