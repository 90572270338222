import { Pagination } from 'antd';
import React from 'react';

interface PaginationBoxProps {
  pageData: any;
  showSizeChanger?: boolean;
  onChangePage?: ({ page, perPage }:any) => void;
  size?: 'default' | 'small';
}

const PaginationBox: React.FC<PaginationBoxProps> = ({ pageData, showSizeChanger = true, onChangePage, ...props }) => {
  const handleChangePage = (page: number, perPage: number) => {
    onChangePage?.({ page, perPage });
  };

  if (!pageData) return null;

  return (
      <div style={{ textAlign: 'center', padding: 20 }}>
        <Pagination
            showQuickJumper
            total={pageData.totalCount}
            showSizeChanger={showSizeChanger}
            onChange={handleChangePage}
            defaultCurrent={1}
            current={pageData.page}
            {...props}
        />
      </div>
  );
};

export default PaginationBox;
