import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, {Dayjs} from "dayjs";
import {date} from "yup";
import isoWeek from "dayjs/plugin/isoWeek";

// Define types for the state
interface TimeEntry {
    date: any;
    month: any;
    year: any;
    project: any;
    id: string;
    totalMinutes: number;
    status: string;
}

interface TimeEntriesState {
    startWeek: any;
    endWeek: any;
    timeEntries?: any[] | any;
    selectedDate?: string;
    weekEntry?: boolean | any;
    weekEntries?: any[]; // Adjust based on the actual structure
    approvedWeekEntries?: any[]; // Adjust based on the actual structure
    rejectedWeekEntries?: any[]; // Adjust based on the actual structure
}

// Utility function to get the current week and month range
const getWeek = () => {
    dayjs.extend(isoWeek);
    const CURRENT_DATE = dayjs();
    const selectedStartWeek = dayjs(CURRENT_DATE).startOf('isoWeek').startOf('day');
    const selectedEndWeek = dayjs(CURRENT_DATE).endOf('isoWeek');
    const selectedStartMonth = dayjs(CURRENT_DATE).startOf('month').startOf('day');
    const selectedEndMonth = dayjs(CURRENT_DATE).endOf('month');

    const START_WEEK = selectedStartWeek > selectedStartMonth ? selectedStartWeek : selectedStartMonth;
    const END_WEEK = selectedEndWeek < selectedEndMonth ? selectedEndWeek : selectedEndMonth;

    return { START_WEEK, END_WEEK, CURRENT_DATE };
};

const { START_WEEK, END_WEEK, CURRENT_DATE } = getWeek();

// Define the initial state
const initialState: TimeEntriesState = {
    startWeek: START_WEEK,
    endWeek: END_WEEK,
    timeEntries: [],
    selectedDate: CURRENT_DATE.format('YYYY-MM-DD'),
    weekEntry: false,
    weekEntries: [],
    approvedWeekEntries: [],
    rejectedWeekEntries: [],
};

// Create a slice using Redux Toolkit
const timeEntriesSlice = createSlice({
    name: 'timeEntries',
    initialState,
    reducers: {
        setTimeData: (state, action: PayloadAction<Partial<any>>) => {
            // Update the state with the new data (merging with existing state)
            let data = action.payload
            if(data?.endWeek){
                data.endWeek = data.endWeek.toISOString()
            }
            if(data?.startWeek){
                data.startWeek = data.startWeek.toISOString()
            }
            Object.assign(state, {...state,...data});
            console.log(action.payload)
        },
        setStartWeek: (state, action: PayloadAction<any>) => {
            state.startWeek = action.payload.toString();
            console.log(action.payload)
        },

        // Reducer to set `endWeek`
        setEndWeek: (state, action: PayloadAction<any>) => {
            state.endWeek = action.payload.toString();
            console.log(action.payload)
        },

        // Reducer to set `selectedDate`
        setSelectedDate: (state, action: PayloadAction<string>) => {
            state.selectedDate = action.payload;
            console.log(action.payload)
        },
    },
});

// Export actions and reducer
export const { setTimeData,setStartWeek,setEndWeek,setSelectedDate } = timeEntriesSlice.actions;
export default timeEntriesSlice.reducer;
