import { Modal, ModalProps } from 'antd'
import React, { FunctionComponent } from 'react'

const ModalBox: FunctionComponent<ModalProps> = (props) => (
    <div>
        <Modal {...props}>{props.children}</Modal>
    </div>
)

ModalBox.defaultProps = {
    width: 600,
    maskClosable: false,
    destroyOnClose: true
}

export default ModalBox
