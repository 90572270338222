import React, { useEffect, useRef, memo } from "react";
import logo from "./logo.svg";
import "./App.css";
// import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
import TopLoaderBox from "./Components/LoaderBox/TopLoaderBox";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FullLoader } from "./Components/LoaderBox/Loader";

export const loaderRef = { barRef: null };
const Routes = FullLoader(() => import("./routes"));
function App() {
  const myRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    loaderRef.barRef = myRef;
  }, []);
  // @ts-ignore
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes></Routes>
        </BrowserRouter>
      </DndProvider>
    </>
  );
}

export default memo(App);
