import React, { useEffect, useRef } from 'react'
import { Button } from 'antd'

interface PageButtonBoxProps {
  refs?: (ref: any) => void;
  dataSource: any;
  onNextPage: (lastRecordId: string ) => void;
  enablePage?: string;
  pageLabel?: string;
  type?: "primary" | "dashed" | "link" | "text" | "default" | undefined;
  loader?: boolean;
  style?: React.CSSProperties;
}

const PageButtonBox: React.FC<PageButtonBoxProps> = ({
                                                       refs,
                                                       dataSource,
                                                       onNextPage,
                                                       enablePage,
                                                       pageLabel,
                                                       type = 'primary',
                                                       loader,
                                                       style
                                                     }) => {
  const buttonRef = useRef<any>(null)

  useEffect(() => {
    if (refs) {
      refs(buttonRef.current)
    }
    return () => {
      if (refs) {
        refs(null)
      }
    }
  }, [refs])

  const onClickNext = () => {
    if (enablePage !== 'loader' && dataSource.length > 0) {
      const lastRecord = dataSource.length > 0 ? dataSource[dataSource.length - 1] : false
      if (lastRecord) {
        onNextPage(lastRecord?.id)
      }
    }
  }

  if (enablePage) {
    return (
        <Button style={style} onClick={onClickNext} type={type} loading={loader}>
          {enablePage === 'loader' ? (
              <>
                <i className="flaticon-reload" /> Loading..
              </>
          ) : (
              <>
                <i className="flaticon-download-5" /> {pageLabel}
              </>
          )}
        </Button>
    )
  }

  return null
}

export default PageButtonBox
