import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {arrayMoveImmutable} from "array-move";



// Define the structure of the state
interface TemplateItem {
    id: string;
    [key: string]: any; // For any additional fields the template items may have
}

interface InitialStateType {
    selectedItem: boolean;
    selectedItems: TemplateItem[];
    template: TemplateItem[];
}

// Define the initial state
const initialState: InitialStateType = {
    selectedItem: false,
    selectedItems: [],
    template: []
};

// Create the slice
const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        // Equivalent to SET_TEMPLATE_DATA
        setTemplateData(state, action: PayloadAction<{ selectedItems: TemplateItem[] }>) {
            state.selectedItems = action.payload.selectedItems;
        },

        // Equivalent to CHANGE_TEMPLATE_POSITION
        changeTemplatePosition(
            state,
            action: PayloadAction<{ id: string, siblingId: string, direction: 'back' | 'forward' }>
        ) {
            const { id, siblingId, direction } = action.payload;
            const index = state.template.findIndex((t) => t.id === id);
            const siblingIndex = state.template.findIndex((t) => t.id === siblingId);

            const fromIdx = direction === 'back' ? index : siblingIndex;
            const toIdx = direction === 'back' ? siblingIndex : index;

            // Use arrayMove to reorder the template
            state.template = arrayMoveImmutable([...state.template], fromIdx, toIdx);
        }
    }
});

// Export the actions
export const { setTemplateData, changeTemplatePosition } = templateSlice.actions;

// Export the reducer
export default templateSlice.reducer;
