// import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
// import { createAppAsyncThunk } from '../withTypes'
import apiClient from "../../util/apiClient";
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

export interface Reactions {
    thumbsUp: number
    tada: number
    heart: number
    rocket: number
    eyes: number
}

export type ReactionName = keyof Reactions

export interface Post {
    id: string
    title: string
    content: string
    user: string
    date: string
    reactions: Reactions
}

type PostUpdate = Pick<Post, 'id' | 'title' | 'content'>
type NewPost = Pick<Post, 'title' | 'content' | 'user'>

interface PostsState {
    posts: Post[]
    status: 'idle' | 'pending' | 'succeeded' | 'rejected'
    error: string | null
}

export const getRoles = createAsyncThunk(
    'roles/getRoles',
    async (query: any) => {
        // const response = await client.get<Post[]>('/fakeApi/posts')
        const url = query ? `roles/getAll?${query}` : `roles/getAll`

        const response = await apiClient(url, 'GET');

        return response.data
    },
    {
        condition(arg, thunkApi) {
            return false;
            // const postsStatus = selectPostsStatus(thunkApi.getState())
            // if (postsStatus !== 'idle') {
            //     return false
            // }
        },
    },
)

// export const addNewPost = createAppAsyncThunk('posts/addNewPost', async (initialPost: NewPost) => {
//     const response = await client.post<Post>('/fakeApi/posts', initialPost)
//     return response.data
// })

const initialState: any = {
    roles: [],
    status: 'idle',
    error: null,
}

const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.pending, (state, action) => {
                state.status = 'pending'
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Save the fetched posts into state
                state.roles = action.payload
            })
            .addCase(getRoles.rejected, (state, action) => {
                state.status = 'rejected'
                state.error = action.error.message ?? 'Unknown Error'
            })
    },
})

// export const { postUpdated, reactionAdded } = rolesSlice.actions

export default rolesSlice.reducer

export const selectAllPosts = (state: RootState) => state.roles.roles

export const selectPostById = (state: RootState, roleId: string) => state.roles.posts.find((role: any) => role.id === roleId)

export const selectPostsStatus = (state: RootState) => state.roles.status
export const selectPostsError = (state: RootState) => state.roles.error
