import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MasterInfoState {
    masterInfo: any;
}

const initialState: MasterInfoState = {
    masterInfo: null,
};

const masterInfoSlice = createSlice({
    name: 'masterInfo',
    initialState,
    reducers: {
        setMasterInfo(state, action: PayloadAction<any>) {
            state.masterInfo = action.payload;
        },
    },
});

export const { setMasterInfo } = masterInfoSlice.actions;
export default masterInfoSlice.reducer;