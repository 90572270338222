import { combineReducers } from 'redux';
import userReducer from './slice/userInfoSlice';
import masterInfoReducer from './slice/masterInfoSlice';
import languageReducer from './slice/languageSlice';
import rolesReducer from './slice/rolesSlice';
import templateReducer from './slice/templateSlice';
import timeEntriesSlice from './slice/timeEntriesSlice';

const rootReducer = combineReducers({
    languageState: languageReducer,
    masterState: masterInfoReducer,
    userState: userReducer,
    roles: rolesReducer,
    template:templateReducer,
    times:timeEntriesSlice
});

export default rootReducer;