import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import _, { get, set } from "lodash";
import moment, { MomentInput } from "moment";
import queryString from "query-string";
import AppConfig from "../config";
import apiClient from "./apiClient";

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

let accessData: string[] = [];

export const updateAccessData = (data: string[]) => {
  const commonAccess = [
    "/app/inbox",
    // '/app/dashboard',
    "/app/changePassword",
    "/app/profile",
    "/app/manage-company",
  ];
  accessData = [...commonAccess, ...data];
};

// export const validateAccess = (accessList:string[],page: string | string[], withPath?: boolean) => {

//     let oldAccessList = JSON.parse(JSON.stringify(accessList));
//     if (accessList.indexOf('adminAccess') >= 0) {
//         return true
//     }
//     // if (accessList && page) {
//     //     if (typeof page === 'string') {
//     //         if (page === 'edit-employee') {
//     //             return true
//     //         }
//     //         if (page === 'purchase-leave-form' || page === 'cash-out-form') {
//     //             return true
//     //         }
//     //
//     //         oldAccessList.push('time-reports-view')
//     //         // store.dispatch(setAccessData(oldAccessList));
//     //         return accessData.includes(
//     //             withPath ? page.substr(5, page.indexOf(':') > 0 ? page.indexOf('/:') - 5 : page.length) : page
//     //         )
//     //     }
//     //
//     //     return page.filter((e) => accessList.includes(e)).length > 0
//     // }
//     if (accessData && page) {
//         if (typeof page === 'string') {
//             if (
//                 page === 'edit-employee' ||
//                 page === 'employees-hcm' ||
//                 page === 'employees-benefits' ||
//                 page === 'employees-compensation' ||
//                 page === 'employees-payroll' ||
//                 page === 'employees-t&a'
//             ) {
//                 return true
//             }
//             if (page === 'purchase-leave-form' || page === 'cash-out-form') {
//                 return true
//             }
//             accessData.push('time-reports-view')
//             return accessData.includes(
//                 withPath ? page.substr(5, page.indexOf(':') > 0 ? page.indexOf('/:') - 5 : page.length) : page
//             )
//         }

//         return page.filter((e) => accessData.includes(e)).length > 0
//     }
// }

export const convertToQuery = (query: any) => {
  let url = "";

  if (query && Object.keys(query).length > 0) {
    const queryArr = Object.keys(query)
      .map((val) => `${val}=${query[val]}`)
      .join("&");
    url += `?${queryArr}`;
  }

  return url;
};

export const convertMinutesToHours = (value: number) => {
  if (value) {
    const num = value;
    const hours = num / 60;
    const rHours = Math.floor(hours);
    const minutes = (hours - rHours) * 60;
    const rMinutes = `${Math.round(minutes)}`;

    return `${rHours.toString().length === 1 ? `0${rHours}` : rHours}:${
      rMinutes.length === 1 ? `0${rMinutes}` : rMinutes
    }`;
  }

  return "00:00";
};

export const TIME_ENABLED_STATUS = ["Created", "Returned", "Rejected"];

export const getStartAndEndWeek = (value: Dayjs) => {
  dayjs.extend(isoWeek);
  const date = dayjs(value);
  const selectedStartWeek = dayjs(date).startOf("isoWeek").startOf("day");
  console.log(selectedStartWeek.toISOString(), "start");
  const selectedEndWeek = dayjs(date).endOf("isoWeek");
  const selectedStartMonth = dayjs(date).startOf("month").startOf("day");
  const selectedEndMonth = dayjs(date).endOf("month");

  const startWeek =
    selectedStartWeek > selectedStartMonth
      ? selectedStartWeek
      : selectedStartMonth;
  const endWeek =
    selectedEndWeek < selectedEndMonth ? selectedEndWeek : selectedEndMonth;
  const startWeekDate = startWeek.format("D");
  const endWeekDate = endWeek.format("D");

  return { startWeek, endWeek, startWeekDate, endWeekDate };
};

export const parseQueryStr = (value: string) => queryString.parse(value);

export const validateAccess = (
  accessList: string[] | undefined,
  page: string | string[] | undefined = "",
  withPath: boolean = false
): boolean => {
  // Guard clauses for undefined/null inputs
  if (!accessList || !Array.isArray(accessList)) {
    console.log("Invalid access list:", { accessList });
    return false;
  }

  if (page === undefined || page === null) {
    console.log("Page is undefined or null");
    return false;
  }

  // Clone the access list
  const userAccessList = [...accessList];

  // Admin access check
  if (userAccessList.includes("adminAccess")) {
    return true;
  }

  // If no page access is required (empty string or empty array), allow access
  if (page === "" || (Array.isArray(page) && page.length === 0)) {
    return true;
  }

  // Define pages that always return true
  const alwaysAccessiblePages = [
    "edit-employee",
    "employees-hcm",
    "employees-benefits",
    "employees-compensation",
    "employees-payroll",
    "employees-t&a",
    "purchase-leave-form",
    "cash-out-form",
  ];

  try {
    // Handle string page
    if (typeof page === "string") {
      // Check if page is in always accessible pages
      if (alwaysAccessiblePages.includes(page)) {
        return true;
      }

      // Add time-reports-view to access list if needed
      if (!userAccessList.includes("time-reports-view")) {
        userAccessList.push("time-reports-view");
      }

      // Handle path processing if withPath is true
      if (withPath && page.length > 0) {
        const processedPage = page.slice(
          5,
          page.indexOf(":") > 0 ? page.indexOf("/:") : page.length
        );
        return userAccessList.includes(processedPage);
      }

      return userAccessList.includes(page);
    }

    // Handle array of pages
    if (Array.isArray(page)) {
      return page.some((p) => userAccessList.includes(p));
    }

    // If page is neither string nor array, deny access
    console.log("Invalid page type:", { page, type: typeof page });
    return false;
  } catch (error) {
    console.error("Error in validateAccess:", {
      error,
      accessList,
      page,
      withPath,
    });
    return false;
  }
};

type DownloadPrintPDF = {
  printType?: "Single";
  params: Record<string, unknown>;
} & Record<string, unknown>;

export const downloadPrintPDF = <T extends DownloadPrintPDF>(
  url: string,
  { printType = "Single", params, ...query }: T,
  isPrint = false
): Promise<string> =>
  new Promise((resolve, reject) => {
    apiClient(
      url,
      "POST",
      { printType, ...query },
      { responseType: "blob", params },
      true
    )
      .then(({ status, data, headers }) => {
        if (status === 200) {
          if (isPrint) {
            print(data);
          } else {
            download(data, headers);
          }
          resolve("success");
        } else {
          reject(new Error("Request failed"));
        }
      })
      .catch((error: any) => reject(error));
  });

export const print = (data: Blob) => {
  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe);

  iframe.style.display = "none";
  iframe.src = window.URL.createObjectURL(data);

  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow?.print();
    }, 1);
  };
};
export const convertQueryStr = (value: Record<string, unknown>) =>
  queryString.stringify(value);
export const download = (data: Blob, headers: Record<string, string>) => {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(data);
  if (headers) {
    a.download = JSON.parse(
      headers["content-disposition"].split("filename=")[1].split(";")[0]
    );
  }
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const avatarLetter = (str: string) => (str ? str.charAt(0) : "");

export const getImageUrl = (path: string, title: string = "") => {
  return path
    ? `${AppConfig.API_URL}/assets/${path}`
    : require("../assets/images/empty_img.png").default;
};

export const emailValidate = (value: string) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(value) === false;
};

export const numberValidate = (value: string) => {
  const pattern = /^[0-9]*$/;

  return pattern.test(value) === false;
};

export const urlValidate = (value: string) => {
  const pattern =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;

  return pattern.test(value) === false;
};
type SelectOption<T> = {
  label: string;
  value: keyof T;
} & T;

export const convertSelectOptions = <T>(
  options: T[],
  label: keyof T | string[],
  value: keyof T
): SelectOption<T>[] =>
  options.map((data) => {
    console.log(options, label, value);
    const formattedLabel =
      typeof label === "object"
        ? label.map((v) => _.get(data, v, v)).join(" ")
        : _.get(data, label);

    const formattedValue = _.get(data, value);
    console.log({ label: formattedLabel, value: formattedValue, ...data });
    return { label: formattedLabel, value: formattedValue, ...data };
  });

export const removeEmptyKeys = <T extends Record<string, any>>(
  values: T
): Partial<T> => {
  const newValue: Partial<T> = {};

  Object.entries(values).forEach(([key, value]) => {
    const isObject =
      typeof value === "object" && value !== null && !Array.isArray(value);
    const isNotEmptyObject = isObject ? Object.keys(value).length > 0 : true;

    if (value !== "" && value !== 0 && value !== null && isNotEmptyObject) {
      newValue[key as keyof T] = value;
    }
  });

  return newValue;
};

export const navigateToPreviousAndNext = <T>(url?: string, params?: T) =>
  new Promise((resolve, reject) => {
    if (url) {
      apiClient(url, "GET", { params }).then((data) => {
        if (data?.result) {
          resolve(data.result);
        } else {
          reject();
        }
      });
    } else {
      reject();
    }
  });

export const getDocPath = (path: string) =>
  path ? `${AppConfig.API_URL}/assets/${path}` : "";

export const checkMoment = (date: MomentInput) =>
  date && moment.isMoment(moment(date)) ? moment(date) : null;

export const checkDayjs = (date: any): Dayjs | null => {
  return date && dayjs.isDayjs(dayjs(date)) ? dayjs(date) : null;
};

const APP_CACHE = { data: {} };

export const SET_DATA = (key: string, value: unknown) => {
  set(APP_CACHE.data, key, value);
};

export const GET_DATA = (key: string) => get(APP_CACHE.data, key);

export const CLEAR_DATA = () => set(APP_CACHE, "data", {});

const ALPHABET =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const generateId = (ID_LENGTH: number = 5) => {
  let rtn = "";

  for (let i = 0; i < (ID_LENGTH || 5); i++) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }

  return rtn;
};

export const validateDateRange = (data: any) => {
  const validFrom = data?.validFrom;
  const validTo = data?.validTo;
  // Check if both dates are present
  if (validFrom && validTo) {
    const fromDate = dayjs(validFrom);
    const toDate = dayjs(validTo);

    // Check if validTo is before validFrom
    return fromDate.isBefore(toDate);
  }
  // If one of the dates is missing, validation is not required
  return true;
};

export const sanitize = (html: string) => {
  const d = document.createElement("div");
  d.innerHTML = html;

  return {
    dangerouslySetInnerHTML: {
      __html: d.innerHTML,
    },
  };
};
